<template>
  <div class="base-info">
    <ul class="check-info">
      <li>
        <span class="label txt-gray-light">真实姓名：</span>
        <span class="info txt-black">{{user.userName}}</span>
      </li>
      <li>
        <span class="label txt-gray-light">身份证号：</span>
        <span class="info txt-black">{{user.idNum}}</span>
      </li>
      <li>
        <span class="require">*</span>
        <span class="label txt-gray-light">银行卡号：</span>
        <input
          type="text"
          placeholder="请输入银行卡号"
          v-model="user.userBankCard"
          size="20"
          maxlength="20"
        />
      </li>
      <li class="waring-info">注：该卡号作为营业收款账号将用于工商注册时身份验证</li>
      <li>
        <span class="require">*</span>
        <span class="label txt-gray-light">电子邮箱：</span>
        <input
          type="text"
          placeholder="请输入电子邮箱"
          v-model="user.applyUserMail"
          size="100"
          maxlength="100"
        />
      </li>
      <li>
        <span class="label txt-gray-light">行业类型：</span>
        <div class="select-right" @click="handleShowSelectBusiness">
          <p
            class="value-placeholder"
            v-if="!businessOptions.selected.industryType && !user.industryType"
          >请选择行业类型</p>
          <p v-else>{{businessOptions.selected.industryType || user.industryType}}</p>
          <img src="@assets/images/user/arrow.png" alt="arrow" v-if="!user.industryType" />
        </div>
      </li>
      <li
        @click="handleToggleAgreementFlag"
        v-if="(businessOptions.selected && businessOptions.selected.id === 2) || user.industryId === 2"
      >
        <span class="mint-radio">
          <span class="mint-radio-core" v-if="!agreementFlag"></span>
          <span class="mint-radio-core mint-radio-core-activity" v-else-if="agreementFlag"></span>
        </span>
        <span class="label txt-gray-light">授权{{isXyk}}平台代为办理食品经营许可证</span>
      </li>
      <div class="bussiness-info" v-if="businessOptions.selected.industryType || user.industryType">
        <p class="bussiness-info-item">
          <span
            class="bussiness-info-label"
          >核定税种: {{businessOptions.selected.taxType || user.taxType}}</span>
          <span class="bussiness-info-warn">注: 以税务局核定为准</span>
        </p>
        <p class="bussiness-info-item">
          <span
            class="bussiness-info-label"
          >开票类目: {{businessOptions.selected.taxCate || user.taxCate}}</span>
        </p>
        <p class="bussiness-info-item">
          <span
            class="bussiness-info-label"
          >经营范围: {{businessOptions.selected.industryRange || user.industryRange}}</span>
          <span class="bussiness-info-warn">注: 以税务局核定为准</span>
        </p>
        <p class="bussiness-info-item">
          <span
            class="bussiness-info-label"
          >名称示例：{{businessOptions.selected.nameDemo || user.nameDemo}}</span>
        </p>
        <p class="bussiness-info-item">
          <span
            class="bussiness-info-label"
          >个税政策：{{businessOptions.selected.taxPolicy || user.taxPolicy}}</span>
        </p>
      </div>
    </ul>
    <hr class="space" />
    <div class="inbu-id-img">
      <ImagePreview
        :imgUrl="this.user.userIdcardImg1 + '/watermark_text'"
        alt="身份证正面"
        v-if="this.user.userIdcardImg1"
      />
      <ImagePreview
        :imgUrl="this.user.userIdcardImg2 + '/watermark_text'"
        alt="身份证反面"
        v-if="this.user.userIdcardImg2"
      />
    </div>
    <div class="footer-bt">
      <mt-button type="danger" size="large" @click.native="throttleHandleNext">下一步</mt-button>
    </div>
    <mt-actionsheet :actions="businessOptions.data" v-model="businessOptions.visible"></mt-actionsheet>
  </div>
</template>

<script>
import { throttle } from "@utils/common";
import { Toast } from "mint-ui";
import {
  getInBuBaseInfo,
  checkidNum,
  getBussinessType
} from "@api/individualBusiness";
import ImagePreview from "@components/image-preview";
export default {
  name: "baseInfo",
  components: {
    ImagePreview
  },
  data() {
    return {
      user: {},
      sheetCancel: false,
      businessOptions: {
        selected: {},
        data: [],
        visible: false
      },
      agreementFlag: false,
      isXyk: window.location.origin.indexOf('h5.kylincloud-saas') > -1 ?'麒麟云服' :'僖游客'
    };
  },
  computed: {},
  watch: {
    "user.userBankCard": function(val) {
      this.user.userBankCard = String(val).replace(/\D/g, "");
    },
    "businessOptions.visible": function(val) {
      if (!val && this.sheetCancel) {
        this.handleSheetCancel();
      }
    }
  },
  mounted() {
    this.getInBuBaseInfo();
    this.getBussinessType();
  },
  methods: {
    handleToggleAgreementFlag() {
      this.agreementFlag = !this.agreementFlag;
    },
    getInBuBaseInfo() {
      getInBuBaseInfo().then(res => {
        if (res.code === 200) {
          if (
            res.data.industryId === null ||
            res.data.industryId === undefined
          ) {
            delete res.data.industryType;
            delete res.data.taxType;
            delete res.data.taxCate;
            delete res.data.industryRange;
            delete res.data.nameDemo;
            delete res.data.taxPolicy;
          }
          this.user = res.data;
        } else {
          Toast(res.message);
        }
      });
    },
    getBussinessType() {
      getBussinessType().then(res => {
        const { data, code, message } = res;
        if (code === 200 && Array.isArray(data) && data.length > 0) {
          this.businessOptions.data = data.map(item => ({
            name: item.industryType,
            method: () => this.handleSelectedBusiness(item)
          }));
        } else {
          Toast(message);
        }
      });
    },
    handleShowSelectBusiness() {
      if (this.user.industryType) return;
      this.sheetCancel = true;
      this.businessOptions.visible = true;
    },
    handleSelectedBusiness(val) {
      this.sheetCancel = false;
      this.businessOptions.selected = val;
    },
    handleSheetCancel() {
      this.businessOptions.selected = {};
    },
    throttleHandleNext: throttle(function() {
      this.handleNext();
    }, 2000),
    handleNext() {
      if (!this.user.userBankCard) {
        Toast("请填写正确的银行卡号");
        return;
      }
      if (!this.user.applyUserMail) {
        Toast("请填写电子邮箱");
        return;
      }
      const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!reg.test(this.user.applyUserMail)) {
        Toast("电子邮箱格式不正确");
        return;
      }
      const values = {
        applyUserMail: this.user.applyUserMail,
        userBankCard: this.user.userBankCard,
        industryId: this.businessOptions.selected.id || this.user.industryId
      };
      if (values.industryId === 2 && !this.agreementFlag) {
        if (this.isXyk) {
          Toast("请授权麒麟云服平台代为办理食品经营许可证");
        } else {
          Toast("请授权僖游客平台代为办理食品经营许可证");
        }
        return;
      }
      checkidNum(values).then(res => {
        if (res.code === 200) {
          this.$router.replace("/individual-business/face-upload");
        } else {
          Toast(res.message);
        }
      });
    }
  }
};
</script>

<style lang='less' scoped>
.base-info {
  padding-bottom: 100px;
}
ul.check-info {
  padding: 20px;
  li {
    margin-bottom: 15px;
    font-size: 1rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    .label {
      margin-right: 5px;
      white-space: nowrap;
    }
    .require {
      color: #ff2500;
    }
    input,
    .select-right {
      display: inline-block;
      width: 60%;
      font-size: 1rem;
      line-height: 2rem;
      border: none;
      border-bottom: 1px solid #dddddd;
    }
    .select-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
        color: #333333;
      }
      .value-placeholder {
        color: #dddddd;
      }
      img {
        width: 4.8vw;
        height: 4.8vw;
      }
    }
  }
  .waring-info {
    height: 4.9333vw;
    font-size: 3.4667vw;
    font-weight: 500;
    color: #b94740;
    line-height: 4.9333vw;
  }
}
.bussiness-info {
  box-sizing: border-box;
  width: 100%;
  padding: 4.4vw 3.8667vw;
  background: #f5f5f5;
  border-radius: 0.8vw;
  display: block;
  span {
    display: block;
  }
  &-item {
    margin: 0;
    margin-bottom: 3.3333vw;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &-label {
    font-size: 4vw;
    font-weight: 500;
    color: #666660;
    line-height: 5.6vw;
  }
  &-warn,
  &-example {
    height: 4.9333vw;
    font-size: 3.4667vw;
    font-weight: 500;
    color: #b94740;
    line-height: 4.9333vw;
  }
  &-example {
    color: #0aabff;
  }
}
.mint-radio {
  margin-right: 1vw;
  margin-top: -2px;
  &-core-activity {
    background-color: #ffffff;
    border-color: #26a2ff;
    border-width: 6px;
    box-sizing: border-box;
  }
}
</style>

<style lang='less'>
.inbu-id-img {
  img {
    width: 78vw;
    height: 43.7333vw;
    border-radius: 2.6667vw;
    display: block;
    margin: 2.9333vw auto;
  }
}
</style>